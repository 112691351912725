const productSlides = [
    // {
    //     title: "Universal Testing Machine",
    //     description:
    //         "Every decision, from how you monetize your story to how long it stays online, is up to you.",
    //     imageUrl:
    //         "https://ik.imagekit.io/protechtest/test/UNIVERSAL_TESTING_MACHINE__2_JR6GVkXGOI.png?updatedAt=1627930057839",
    // },
    // {
    //     title: "Power Pack",
    //     description:
    //         "With the ability to command every pixel, each story can be designed to fit your brand.",
    //     imageUrl:
    //         "https://ik.imagekit.io/protechtest/test/POWER_PACK_4z5Rwuwf0JO.png?updatedAt=1627930057323",
    // },
    {
        title: "Universal Testing Machine",
        description: ['Dual column self-reacting load frame',
            'State-of-the-art digital servo control with 64-bit data acquisition and 40-bit servo-loop calculation',
            'Customizable, energy-efficient Hydraulic Power Supply for best results',
            '0 - 200 Hz cyclic frequency',
            'Hydraulic Lifts and Clamps/De-clamp for upper cross head movement and locking',
            'Actuator on top/bottom crosshead',
            'Mechanical/Hydraulic Anti Vibration Pads',
            'Columns are hard chrome plated',
            'Frame stiffness 400 to 2000 kN/mm',
            'Touch screen operator control panel for basic machine operation viz., hydraulics on/off, low/high pressure, E-stop, actuator jogging up/down slow/fast, cross head height adjustment & lock/unlock, hydraulic grips operation, etc.',
            'Intellimount feature to protect the sample during test set-up. Specimen mountings starts in stroke control mode and automatically switches to load control mode whenever specified load is applied.',
        ],
        imageUrl:
            "https://ik.imagekit.io/protech/Hittites/UTM_xaLwAtl2A6B.png?ik-sdk-version=javascript-1.4.3&updatedAt=1643820608639",
    },
    // {
    //     title: "Power Pack",
    //     description: [
    //         'Energy efficient servo pumps',
    //         'Contamination insensitive hydraulics ',
    //         'Industry standard sealing for leak free operations and long life ',
    //         'Runs on demand PLC/ PLI control with digital display for pressure, temperature, hours, frequency, current, etc. ',
    //         'Auto flow control according to the demand from the test under progress ',
    //         'Piping interfaced manifolds ',
    //         'Easy access for maintenance ',
    //         'Dump valves ',
    //         'Flexible hydraulic hose connections ',
    //         'Low maintenance cost ',
    //         'Rated pressure – up to 210 bar with safety cut off at 230 bar ',
    //         'Water oil heat exchangers, plate/tube ',
    //         'Local or remote control ',
    //         'Solenoid controlled low/high pressure operation ',
    //         'Accumulators for pulse free flow ',
    //         'Rated to operate under wide range of temperatures ',
    //         'Rated to work in unprotected shop floor environment ',
    //         'Manifold and hoses of standard 5 meter',
    //         'Super clean Mobil DTE 25 or equivalent grade for long life & efficient delivery',
    //     ],
    //     imageUrl:
    //         "https://images.unsplash.com/photo-1561532089-cb18d10f86b4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=100",
    // },
];

export { productSlides };