import React, { memo } from 'react'
import GoogleMapReact from 'google-map-react'
import "./style.scss"
import { ComposableMap, Marker, Geographies, Geography, ZoomableGroup, Annotation } from "react-simple-maps";
import { MdLocationOn } from 'react-icons/md'
import { geoPatterson } from 'd3-geo-projection'
const defaultProps = {
    center: {
        lat: 13.004829544638373,
        lng: 77.51468633773463,
    },
    zoom: 19,
}
// 13.004829544638373, 77.51468633773463

const LocationPin = ({ text }) => (
    <div className="pin">
        <div className="pin-icon">
            <MdLocationOn />
        </div>
        <p className="pin-text">{text}</p>
    </div>
)

// const geoUrl =
//     "https://unpkg.com/world-atlas@1.1.4/world/110m.json";

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [


    { y: "-10", dx: 0, dy: -100, name: "Head Quarter", coordinates: [77.51724152416449, 13.006061429808383] },
    { y: "-10", dx: 45, dy: -60, name: "RV Testing", coordinates: [80.18030121388395, 13.028077763651373] },
    { y: "-10", dx: 0, dy: -70, name: "Doli Electrokic GmbH", coordinates: [11.53447008032861, 48.12951962069885] }
];

const projection = geoPatterson()
    .translate([800 / 2, 600 / 2])
    .scale(135);

const GoogleMap = ({ setTooltipContent }) => (



    // </div>
    <div className='map-container'>
        <ComposableMap
            width={800}
            height={600}
            projection={projection}
        >
            {/* <ZoomableGroup zoom={2}> */}

            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies.map(geo => <Geography key={geo.rsmKey} fill="#909090" geography={geo} />)
                }
            </Geographies>
            {markers.map(({ name, coordinates, dx, dy, y, x }, idx) => (
                <React.Fragment key={idx}>
                    <Annotation
                        subject={coordinates}
                        dx={dx}
                        dy={dy}
                        curve={0}


                        connectorProps={{
                            stroke: "#ffd133",
                            strokeWidth: 2,
                            strokeLinecap: "round",
                        }}
                    >


                        {/* <g
                            fill="none"
                            stroke="#ffd133"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="translate(-12, -24)"
                        >
                            <circle cx="12" cy="10" r="3" fill='#ffd133' />
                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />

                        </g> */}
                        <text x="0" y={y} textAnchor="middle" alignmentBaseline="middle" fill="#ffd133" fontSize="0.9em">
                            {name}
                        </text>

                    </Annotation>
                    <Marker key={name} coordinates={coordinates}
                    // onMouseEnter={() => {
                    //     setTooltipContent("Map tooltip");

                    // }}
                    // onMouseLeave={() => {
                    //     setTooltipContent("");
                    // }}
                    >

                        {/* <g
                            fill="none"
                            stroke="#ffd133"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="translate(-12, -24)"
                        >
                            <circle cx="12" cy="10" r="3" />
                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                        </g> */}

                        <circle r={2} fill="#ffd133" stroke="#ffd133" strokeWidth={1} />
                        {/* <text
                            textAnchor="middle"
                            style={{ fill: "#ffd133", }}
                        >
                            {name}
                        </text> */}



                    </Marker>
                </React.Fragment>
            ))}

            {/* </ZoomableGroup> */}
        </ComposableMap>
    </div >
)

export default memo(GoogleMap)


