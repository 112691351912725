
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import icon from '../images/icon.png';
function SEO({ description, lang, meta, title }) {

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    );


    const defaultTitle = site.siteMetadata?.title;


    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={defaultTitle}
            // titleTemplate={`%s | ${defaultTitle}`}
            // titleTemplate={defaultTitle ? defaultTitle : defaultTitle}
            meta={[
                {
                    name: `title`,
                    content: title,
                },
                {
                    name: `description`,
                    content: `Hittites Technology India Limited provides high quality products and services to the customers. What differentiates us from other businesses is our ability to truly connect with our customers, and provide them exceptional and compassionate service they deserve.`,
                },
                {
                    property: `og:site_name`,
                    content: `Hittites Technology`,
                },
                {
                    property: `og:title`,
                    content: `Hittites Technology India Limited`,
                },
                {
                    property: `og:description`,
                    content: `Hittites Technology India Limited provides high quality products and services to the customers. What differentiates us from other businesses is our ability to truly connect with our customers, and provide them exceptional and compassionate service they deserve.`,

                },
                {
                    property: `og:image`,
                    itemprop: `image`,
                    content: '../images/icon.png',

                },
                {
                    property: `og:image:width`,
                    content: `300`,
                },
                {
                    property: `og:image:height`,
                    content: `300`,
                },
                {
                    property: `og:image:type`,
                    content: `image/jpeg`,
                },

                {
                    property: `og:url`,
                    content: `https://www.hittitesindia.com/`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `https://www.hittitesindia.com/`,
                },

                {
                    name: `twitter:title`,
                    content: `Hittites Technology India Limited`,

                },
                {
                    name: `twitter:url`,
                    content: `https://www.hittitesindia.com/`,

                },
                {
                    name: `twitter:description`,
                    content: `Hittites Technology India Limited provides high quality products and services to the customers. What differentiates us from other businesses is our ability to truly connect with our customers, and provide them exceptional and compassionate service they deserve.`,

                },
                {
                    name: `twitter:image`,
                    content: '../images/icon.png',
                },
            ].concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
};

export default SEO;