import React, { useState, useEffect, Fragment } from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import CustomCursor from '../components/CustomCursor';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Seo from '../components/seo';
import Hero from '../components/Hero/Hero';
import About from '../components/About/About'
import Contact from '../components/Contact';
import FeatureSlides from "../components/FeatureSlides";
import Footer from '../Footer';

import "../styles/home.scss";
import Testimonials from '../components/Testimonials';
import BrochureSection from '../components/BrochureSection/BrochureSection';
import GoogleMap from '../components/GoogleMap/GoogleMap';
import GetInTouch from '../components/Contact/GetInTouch';
import SingleFeature from '../components/FeatureSlides/SingleFeature';
import Testimonial from '../components/ImageTestimonial/Testimonial';

export default function Index() {

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 750);
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);


  }, [])




  return (
    <Fragment>
      <Seo />
      <CustomCursor />

      {
        loader ? <div className="loading">Loading...</div> : (
          <div className="main-container"
            id="main-container"
          >
            <Hero />
            <About />
            {/* <BrochureSection /> */}
            {/* <FeatureSlides /> */}
            <SingleFeature />
            <Contact />
            <Testimonial />
            {/* <Testimonials /> */}
            <GetInTouch />
            <Footer />
          </div>
        )
      }

    </Fragment>

  )
}
