import React, { useEffect } from "react";
import "./style.scss"



import gsap from "gsap";
import SectionHeader from "../SectionHeader";


export default function ContactInfo() {

    // useEffect(() => {

    //     gsap.to('.con',
    //         {
    //             xPercent: -50,
    //             ease: 'none',
    //             duration: 6,
    //             repeat: -1,
    //         }
    //     )

    // }, [])
    return (

        <div className="contact-info-container">
            <SectionHeader title="Our Global Network" />
            <div>

                <h2 className="heading">
                    Head Quarter
                </h2>
                <p className="sub-heading">
                    S G Kaval, 26, 24th Cross, H G Halli, Doddanna Industrial Estate, Peenya 2nd
                    Stage, Bengaluru, Karnataka - 560091, India
                </p>

            </div>
            <br />
            <br />
            <br />

            <SectionHeader title="Our dealers" />

            <div className="dealers-container">
                <div>
                    <h2 className="heading">
                        RV Testing
                    </h2>
                    <p className="sub-heading">
                        Plot No. 89, Door No. 5/29A, Vanavil Flats – A2, Natesan Nagar, Ramapuram,
                        Chennai – 600089, India
                    </p>

                </div>
                <hr />
                <div>


                    <h2 className="heading">
                        Doli Electrokic GmbH
                    </h2>
                    <p className="sub-heading">
                        Rudolf-Diesel-Strabe 3 72525 Munsigen
                    </p>

                </div>

            </div>
        </div>

    )
}
