import React from 'react';
import "./style.scss"
import { productSlides } from "./data";
import SectionHeader from '../SectionHeader';
import "./single-feature.scss";

export default function SingleFeature() {
    return <div className="feature-slides-container">

        {productSlides.map((feature, index) => {
            return (
                <React.Fragment key={index}>
                    <div className="feature-slides-left">
                        <SectionHeader title={'products'} />
                        <h3 className="feature-slide-title">{feature.title}</h3>

                        {/* <hr className="divider" /> */}
                        <hr />
                        <ul className="feature-slide-description">
                            {feature.description.map((item, i) => (
                                <li className="feature-slide-description-points" key={i}>{item}</li>
                            ))}

                        </ul>


                        {/* <button className="feature-watch"
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Watch now
            </button> */}

                    </div>
                    <div>

                        <img
                            // key={feature.imageUrl}
                            alt={feature.title}
                            src={feature.imageUrl} />
                    </div>
                </React.Fragment>
            );
        })}
        {/* <div  className="feature-slides-right">
            <img
                key={imageUrl}
                alt={title}
                style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: "cover", backgroundPosition: "center" }}
            />
        </div> */}

    </div>;
}
