import './style.scss';
import React, { useState } from 'react'
import SectionHeader from '../SectionHeader';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


// const TestimonialContent = [
//     {
//         name: 'John Doe',
//         company: 'Company Name',
//         image_link: 'https://ik.imagekit.io/protech/Hittites/tcr1_PVrN5MT-oCdL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866836466',
//         testimonial_link: '#',
//     },
//     {
//         name: 'John Doe',
//         company: 'Company Name',
//         image_link: 'https://ik.imagekit.io/protech/Hittites/tcr2_bDM6HQxzE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866836880',
//         testimonial_link: '#',
//     },
//     {
//         name: 'John Doe',
//         company: 'Company Name',
//         image_link: 'https://ik.imagekit.io/protech/Hittites/honeywell_uV_gSBTBfrHKm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866837057',
//         testimonial_link: '#',
//     }
// ];

const companyLogo = [
    {
        title: 'Honeywell',
        image_link: 'https://ik.imagekit.io/protech/Hittites/Honeywell-Logo_DF2_iuQGl7DV.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644868717875',
    },
    {
        title: 'TCR Engineering',
        image_link: 'https://ik.imagekit.io/protech/Hittites/tcr_engineering_EI_V2-9Zc?ik-sdk-version=javascript-1.4.3&updatedAt=1646145285659',
    }
]

const images = [
    {
        src: "https://ik.imagekit.io/protech/Hittites/honeywell_uV_gSBTBfrHKm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866837057",
        title: 'Honeywell Technologies Solutions Lab',
    },

    {
        src: "https://ik.imagekit.io/protech/Hittites/tcr1_PVrN5MT-oCdL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866836466",

        title: 'TCR Engineering Services Pvt. Ltd.',
    },
    {
        src: "https://ik.imagekit.io/protech/Hittites/tcr2_bDM6HQxzE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644866836880",
        title: 'TCR Engineering Services Pvt. Ltd.',
    },


];

function Testimonial() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    return (
        <div className='testimonial-container'>



            <SectionHeader title="Our Clients" />
            <div className='testimonial-content'>


                {
                    companyLogo.map((item, index) => {
                        return (
                            <div className='company-logo-container' key={index}>
                                <img src={item.image_link} alt={item.title} onClick={
                                    () => {
                                        setIsOpen(
                                            true
                                        );
                                        setPhotoIndex(index);
                                    }
                                } />
                            </div>
                        )
                    })
                }
            </div>





            <button className='more-info-btn' onClick={() => setIsOpen(true)}>More Info</button>


            {isOpen && (
                <Lightbox
                    imageTitle={images[photoIndex].title}

                    discourageDownloads
                    // imageCaption={`Image ${photoIndex + 1}`}
                    mainSrc={images[photoIndex].src}
                    nextSrc={images[(photoIndex + 1) % images.length].src}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}

        </div>

    )
}

export default Testimonial;
